import * as React from 'react';
import master from '../../Master';
import { Checkbox } from '../Input';

interface PlaceCodeEverguardInputProps {
  shapeSchemeId: number;
  everguardThreshold: number | undefined;
  onChange: (id: number | undefined) => void;
}

export const PlaceCodeEverguardInput: React.FC<PlaceCodeEverguardInputProps> = function (props) {
  const useEverguard = props.everguardThreshold != undefined;

  return (
    <div>
      {(function () {
        if (master.Shapes.find(shapeData => shapeData.id === props.shapeSchemeId)?.input_field_ids.some(fieldId => fieldId == 5)) {
          return (
            <>
              <div style={{gap: 8, alignItems: "center", display: 'flex'}}>
                <label className="App-label">{"エバーガード用の補修コード"}</label>
                <Checkbox value={useEverguard} onChange={(checked) => props.onChange(checked ? 0.5 : undefined)} />
              </div>
              {(() => {
                if (useEverguard) {
                  return (
                    <>
                      <label>{"浮き面積設定"}</label>
                      <div style={{gap: 8, alignItems: "center", display: 'flex'}}>
                        <input value={props.everguardThreshold ?? 0.5} onChange={(event) => {
                          const parsedValue = Number(event.target.value);
                          if (!isNaN(parsedValue)) {
                            props.onChange(parsedValue);
                          } else {
                            props.onChange(0.5);
                          }
                        }} />
                        <label>{master.InputFields[4].defaultUnit} 以上</label>
                      </div>
                    </>
                  );
                }
                return;
              })()}
            </>
          );
        }

        return;
      })()}
    </div>
  );
};
