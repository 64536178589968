import * as React from 'react';
import {
  Link,
  RouteComponentProps,
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
import { GraphQLErrorDialog } from '../components/Dialogs';
import { useGetPlaceQuery } from '../generated/graphql';
import { PlaceCreatePage } from './PlaceCreatePage';
import { PlaceIndexPage } from './PlaceIndexPage';
import { PlaceCodesPage } from './PlaceCodesPage';
import { PlaceCreateCodePage } from './PlaceCreateCodePage';
import { PlaceCreateSortPage } from './PlaceCreateSortPage';
import { PlaceSortsPage } from './PlaceSortsPage';
import { PlaceCodeAndSortPage } from './PlaceCodeAndSortPage';
import { PlaceCreateCodeAndSortPage } from './PlaceCreateCodeAndSortPage';
import { PlaceWorkListPage } from './PlaceWorkListPage';
import { PlaceCreateWorkPage } from './PlaceCreateWorkPage';
import { PlaceDxfListPage } from './PlaceDxfListPage';
import { PlaceDxfUploadPage } from './PlaceDxfUploadPage';
import { PlaceDxfPicturesPage } from './PlaceDxfPicturesPage';
import { PlaceDxfMarkersPage } from './PlaceDxfMarkersPage';
import { NotFoundPage } from './NotFoundPage';
import { Loading } from '../Loading';
import './PlacePage.css';

type IPlacePageProps = RouteComponentProps<{ id: string }> & {};

const navPathRegex = /^\/places\/[0-9]+\/([^/]+)(\/.+)*$/;

/**
 * 物件ページ
 */
export const PlacePage: React.FC<IPlacePageProps> = function (props) {
  const placeId = parseInt(props.match.params.id);
  const getPlaceResult = useGetPlaceQuery({
    variables: {
      placeId,
    },
  });

  if (getPlaceResult.error) {
    return <GraphQLErrorDialog error={getPlaceResult.error} />;
  }

  if (getPlaceResult.loading || !getPlaceResult.data) {
    return <Loading />;
  }

  const path = props.location.pathname;

  const match = navPathRegex.exec(path);
  let subpath: string | null = null;
  if (match) {
    subpath = match[1];
  }
  if (subpath === 'edit') {
    subpath = null;
  }

  const place = getPlaceResult.data.getPlace;

  function navigatorItemsCount(items: {}[]) {
    return (
      <span
        className={
          'PlacePage-navigator_items_count' + (items.length === 0 ? ' red' : '')
        }
      >
        {items.length}
      </span>
    );
  }

  function selectedPageClassName(pageName: string | null) {
    return subpath === pageName ? 'PlacePage-navigator_selected' : '';
  }

  return (
    <div className="PlacePage-container">
      <h1>{place.name}</h1>
      <div className="PlacePage-navigator">
        <nav>
          <ul>
            <Link to={`/places/${placeId}`}>
              <li className={selectedPageClassName(null)}>
                <div className='PlacePage-navigator_item'>
                  <span className='PlacePage-navigator_item_name'>基本情報</span>
                </div>
              </li>
            </Link>
            <Link to={`/places/${placeId}/sorts`}>
              <li className={selectedPageClassName('sorts')}>
                <div className='PlacePage-navigator_item'>
                  <span className='PlacePage-navigator_item_name'>補修分類</span>
                  {navigatorItemsCount(place.sorts)}
                </div>
              </li>
            </Link>
            <Link to={`/places/${placeId}/codes`}>
              <li className={selectedPageClassName('codes')}>
                <div className='PlacePage-navigator_item'>
                  <span className='PlacePage-navigator_item_name'>補修コード</span>
                  {navigatorItemsCount(place.codes)}
                </div>
              </li>
            </Link>
            <Link to={`/places/${placeId}/codes_and_sorts`}>
              <li className={selectedPageClassName('codes_and_sorts')}>
                <div className='PlacePage-navigator_item'>
                  <span className='PlacePage-navigator_item_name'>分類 × コード</span>
                </div>
              </li>
            </Link>
            <Link to={`/places/${placeId}/works`}>
              <li className={selectedPageClassName('works')}>
                <div className='PlacePage-navigator_item'>
                  <span className='PlacePage-navigator_item_name'>工種</span>
                </div>
              </li>
            </Link>
            <Link to={`/places/${placeId}/faces`}>
              <li className={selectedPageClassName('faces')}>
                <div className='PlacePage-navigator_item'>
                  <span className='PlacePage-navigator_item_name'>図面</span>
                  {navigatorItemsCount(place.faces)}
                </div>
              </li>
            </Link>
          </ul>
        </nav>
      </div>
      <div>
        <Switch>
          <Route
            exact
            path="/places/:placeId"
            component={withRouter((props) => (
              <PlaceIndexPage {...props} />
            ))}
          />
          <Route
            path="/places/:placeId/edit"
            component={withRouter((props) => (
              <PlaceCreatePage
                {...props}
                existingId={parseInt(props.match.params.placeId)}
              />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/codes"
            component={withRouter((props) => (
              <PlaceCodesPage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/codes/new"
            component={withRouter((props) => (
              <PlaceCreateCodePage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/codes/:codeId/edit"
            component={withRouter((props) => (
              <PlaceCreateCodePage
                {...props}
                existingId={parseInt(props.match.params.codeId)}
              />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/sorts"
            component={withRouter((props) => (
              <PlaceSortsPage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/sorts/new"
            component={withRouter((props) => (
              <PlaceCreateSortPage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/sorts/:sortId/edit"
            component={withRouter((props) => (
              <PlaceCreateSortPage
                {...props}
                existingId={parseInt(props.match.params.sortId)}
              />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/codes_and_sorts"
            component={withRouter((props) => (
              <PlaceCodeAndSortPage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/codes_and_sorts/:codeId/:sortId/:gteEverguardThreshold/edit"
            component={withRouter((props) => (
              <PlaceCreateCodeAndSortPage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/works"
            component={withRouter((props) => (
              <PlaceWorkListPage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/works/new"
            component={withRouter((props) => (
              <PlaceCreateWorkPage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/works/:workId/edit"
            component={withRouter((props) => (
              <PlaceCreateWorkPage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/faces"
            component={withRouter((props) => (
              <PlaceDxfListPage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/faces/:placeFaceId/pictures"
            component={withRouter((props) => (
              <PlaceDxfPicturesPage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/faces/:placeFaceId/markers"
            component={withRouter((props) => (
              <PlaceDxfMarkersPage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/faces/new"
            component={withRouter((props) => (
              <PlaceDxfUploadPage {...props} />
            ))}
          />
          <Route
            exact
            path="/places/:placeId/faces/:placeFaceId/edit"
            component={withRouter((props) => (
              <PlaceDxfUploadPage
                {...props}
                existingId={parseInt(props.match.params.placeFaceId)}
              />
            ))}
          />
          <Route component={() => <NotFoundPage />} />
        </Switch>
      </div>
    </div>
  );
};
