import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { EverguardSetting, Maybe, PlaceCodeEverguardClass, useGetPlaceCodesAndSortsLazyQuery, useUpdatePlaceCodeAndSortDisabledMutation, useUpdatePlaceCodesAndSortsMutation } from '../generated/graphql';
import { HatchImage, LineImage } from '../components/ImageSelect';
import { Loading } from '../Loading';
import { Button } from '../components/Button';
import { GraphQLErrorDialog } from '../components/Dialogs';
import './PlaceCodeAndSortPage.css';

type IPlaceCodeAndSortPageProps = RouteComponentProps<{ placeId: string }> & {};

export const PlaceCodeAndSortPage: React.FC<IPlaceCodeAndSortPageProps> = function (props) {
  const placeId = parseInt(props.match.params.placeId);
  const history = useHistory();

  const [getCodesAndSorts, getCodesAndSortsResult] = useGetPlaceCodesAndSortsLazyQuery({
    variables: { placeId }
  });
  const [updateDisabled, updateDisabledResult] = useUpdatePlaceCodeAndSortDisabledMutation({
    onCompleted() {
      getCodesAndSorts();
    }
  });
  const [updateCodeAndSortConfig, updateCodeAndSortConfigResult] = useUpdatePlaceCodesAndSortsMutation({
    onCompleted() {
      getCodesAndSorts();
    }
  });

  if (!getCodesAndSortsResult.called) {
    getCodesAndSorts();
  }

  if (getCodesAndSortsResult.error) {
    return <GraphQLErrorDialog error={getCodesAndSortsResult.error} />;
  }

  if (getCodesAndSortsResult.loading || !getCodesAndSortsResult.data) {
    return <Loading />;
  }

  const codes = [ ...getCodesAndSortsResult.data.getPlace.codes ] as { id: number | null, name: string, everguard_setting?: EverguardSetting}[];
  codes.push({ id: null, name: '指定なし' });

  const sorts = [ ...getCodesAndSortsResult.data.getPlace.sorts ] as { id: number | null, name: string }[];
  sorts.push({ id: null, name: '指定なし' });

  const enabledConfigs = [];
  const disabledConfigs = [];
  for (const sort of sorts) {
    for (const code of codes) {
      var codeEverguardId = code.everguard_setting?.id ?? null;
      const codeAndSortConfigs = getCodesAndSortsResult.data.getPlace.code_sort_configs
        .filter(config => config.code_id === code.id && config.sort_id === sort.id && config.place_code_everguard_id === codeEverguardId);

      if (code.everguard_setting && codeEverguardId) {
        // エバーガード設定がある場合
        // "エバーガード閾値以上", "エバーガード閾値未満"のコードを追加する
        [true, false].forEach(gteThreshold => {
          const config = codeAndSortConfigs.find(config => config.gte_everguard_threshold === gteThreshold);
          const codeName = `${code.name}（${code.everguard_setting?.everguard_threshold}㎡ ${gteThreshold ? "以上" : "未満"}）`;
          const configs = { code: { ...code, name: codeName }, sort, config, gteEverguardThreshold: gteThreshold };
          if (config?.disabled) {
            disabledConfigs.push(configs);
          } else {
            enabledConfigs.push(configs);
          }
        });
      } else {
        // エバーガード設定がない場合
        const config = codeAndSortConfigs[0];
        const configs = { code, sort, config, gteEverguardThreshold: false };
        if (config?.disabled) {
          disabledConfigs.push(configs);
        } else {
          enabledConfigs.push(configs);
        }
      }
    }
  }

  const codeAndSortConfigs = enabledConfigs.concat(disabledConfigs);

  return (
    <div className="PlaceSortsPage-container">
      <Loading open={updateDisabledResult.called && updateDisabledResult.loading} />
      <table className="App-table">
        <thead>
          <th>補修分類</th>
          <th>補修コード</th>
          <th>前置文字列</th>
          <th>画層の色</th>
          <th>線種</th>
          <th>テクスチャ</th>
          <th colSpan={2}>操作</th>
        </thead>
        <tbody>
        {codeAndSortConfigs.map((args => {
          const { code, sort, config, gteEverguardThreshold } = args;
          
          const codeEverguardId = code.everguard_setting?.id ?? undefined;
          const prefix = config?.prefix ?? "";
          const color = config?.color ?? '#FFFFFF';
          const lineType = config?.line_type ?? 0;
          const hatchType = config?.hatch_type ?? 0;
          const disabled = config?.disabled ?? false;

          let className;
          if (disabled) {
            className = "PlaceCodeAndSortPage-disabled-config";
          }

          return (
            <tr className={className}>
              <td>{sort.name}</td>
              <td>{code.name}</td>
              <td>{prefix}</td>
              <td style={{ backgroundColor: color }}>
                {color}
              </td>
              <td>
                <div className="PlaceCodeAndSortPage-image">
                  <LineImage id={lineType} />
                </div>
              </td>
              <td>
                <div className="PlaceCodeAndSortPage-image">
                  <HatchImage id={hatchType} />
                </div>
              </td>
              <td>
                <Button 
                  onClick={() => {
                    history.push(`/places/${placeId}/codes_and_sorts/${code.id ?? 'none'}/${sort.id ?? 'none'}/${gteEverguardThreshold}/edit`); 
                  }}
                  mini={true}
                  color="white"
                >
                  編集
                </Button>
              </td>
              <td>
                <Button 
                  onClick={() => {
                    const input = {
                      place_id: placeId,
                      code_id: code.id,
                      sort_id: sort.id,
                      place_code_everguard_id: codeEverguardId,
                      prefix: "",
                      color: '#FFFFFF',
                      line_type: 0,
                      hatch_type: 0,
                      // 設定がないデフォルトではdisabledはfalseになっているので
                      // 設定のデータを作成する場合は初期をtrueにする
                      disabled: true,  
                      gte_everguard_threshold: gteEverguardThreshold,
                    };

                    // コンフィグは設定されるときに初めて組み合わせに対する設定を作成するので
                    // 存在しない場合がある
                    if (!config) {
                      updateCodeAndSortConfig({
                        variables: {
                          input: input
                        }
                      });
                    } else {
                      // 設定が存在する場合はフラグの入れ替えだけ行う
                      updateDisabled({
                        variables: {
                          placeId: placeId,
                          codeId: code.id,
                          sortId: sort.id,
                          placeCodeEverguardId: codeEverguardId,
                          gteEverguardThreshold: gteEverguardThreshold,
                          disabled: !disabled,
                        }
                      });
                    }

                    // エバーガード設定が存在するかどうかを確認する
                    // 存在する場合はペアのコンフィグ作成, 若しくは更新を行う
                    if (codeEverguardId !== undefined) {
                      var pairCodeAndSortConfig = codeAndSortConfigs.find(codeAndSortConfig => 
                        codeAndSortConfig.code.id === code.id && 
                        codeAndSortConfig.sort.id === sort.id &&
                        codeAndSortConfig.gteEverguardThreshold !== gteEverguardThreshold
                      );

                      if (pairCodeAndSortConfig !== undefined) {
                        // ペアになるコンフィグが未生成の場合は生成する
                        if (pairCodeAndSortConfig.config === undefined) {
                          updateCodeAndSortConfig({
                            variables: {
                              input: { ...input, gte_everguard_threshold: !gteEverguardThreshold }
                            }
                          });
                        } else {
                          updateDisabled({
                            variables: {
                              placeId: placeId,
                              codeId: code.id,
                              sortId: sort.id,
                              placeCodeEverguardId: codeEverguardId,
                              gteEverguardThreshold: !gteEverguardThreshold,
                              disabled: !disabled,
                            }
                          });
                        }
                      }
                    }
                  }}
                  mini={true}
                  color={disabled ? 'normal' : 'red'}
                >
                  {disabled ? '有効化' : '無効化'}
                </Button>
              </td>
            </tr>
          );
        }))}
        </tbody>
      </table>
    </div>
  );
};
