import * as React from 'react';
import { SketchPicker } from 'react-color';
import { RouteComponentProps, useHistory } from 'react-router';
import { useGetEverguyardSettingLazyQuery, useGetPlaceCodeNameLazyQuery, useGetPlaceCodesAndSortsQuery, useGetPlaceSortNameLazyQuery, useUpdatePlaceCodesAndSortsMutation } from '../generated/graphql';
import { LineSelect, HatchSelect } from '../components/ImageSelect';
import { Loading } from '../Loading';

import './PlaceCreateCodeAndSortPage.css';
import { Button } from '../components/Button';
import { GraphQLMutationErrorDialog } from '../components/Dialogs';
import { Input } from '../components/Input';

type IPlaceCreateCodeAndSortPageProps = RouteComponentProps<{
    placeId: string,
    codeId: string,
    sortId: string,
    gteEverguardThreshold: string,
}> & {};

export const PlaceCreateCodeAndSortPage: React.FC<IPlaceCreateCodeAndSortPageProps> = function (props) {
  const placeId = parseInt(props.match.params.placeId);
  const placeCodeId = props.match.params.codeId === 'none' ? null : parseInt(props.match.params.codeId);
  const placeSortId = props.match.params.sortId === 'none' ? null : parseInt(props.match.params.sortId);
  const gteEverguardThreshold = props.match.params.gteEverguardThreshold === 'true' ? true : false;
  const history = useHistory();

  const [prefix, setPrefix] = React.useState('');
  const [color, setColor] = React.useState('#FFFFFF');
  const [lineType, setLineType] = React.useState(0);
  const [hatchType, setHatchType] = React.useState(0);
  const [getPlaceCodeName, getPlaceCodeNameResult] = useGetPlaceCodeNameLazyQuery();
  const [getPlaceSortName, getPlaceSortNameResult] = useGetPlaceSortNameLazyQuery();
  const [getEverguardSetting, getEverguardSettingResult] = useGetEverguyardSettingLazyQuery();
  const [updatePlaceCodeSortConfig, updatePlaceCodeSortConfigResult] = useUpdatePlaceCodesAndSortsMutation({
    onCompleted() {
      history.push(`/places/${placeId}/codes_and_sorts`);
    }
  });
  const getPlaceCodeAndSortResult = useGetPlaceCodesAndSortsQuery({
    variables: { placeId },
    onCompleted(data) {
      const config = data.getPlace.code_sort_configs.find(config =>
        config.code_id === placeCodeId && config.sort_id === placeSortId && config.gte_everguard_threshold === gteEverguardThreshold);

      if (!config) {
        return;
      }

      setPrefix(config.prefix);
      setColor(config.color);
      setHatchType(config.hatch_type);
      setLineType(config.line_type);
    }
  });

  if (placeCodeId) {
    if (!getPlaceCodeNameResult.called) {
      getPlaceCodeName({ variables: { placeCodeId } });
    }
    if (!getEverguardSettingResult.called) {
      getEverguardSetting({ variables: { placeCodeId } });
    }
  }
  if (!getPlaceSortNameResult.called && placeSortId) {
    getPlaceSortName({ variables: { placeSortId } });
  }

  if ((getPlaceCodeNameResult.called && (getPlaceCodeNameResult.loading || !getPlaceCodeNameResult.data)) ||
      (getPlaceSortNameResult.called && (getPlaceSortNameResult.loading || !getPlaceSortNameResult.data)) ||
      (getPlaceCodeAndSortResult.loading && !getPlaceCodeAndSortResult.data)) {
    return <Loading />;
  }
  if (updatePlaceCodeSortConfigResult.error) {
    return <GraphQLMutationErrorDialog error={updatePlaceCodeSortConfigResult.error} />;
  }

  let codeName;
  if (getPlaceCodeNameResult.data) {
    codeName = getPlaceCodeNameResult.data.getPlaceCode.name;
  } else {
    codeName = '指定なし';
  }
  if (getEverguardSettingResult.data?.getEverguardSetting) {
    if (gteEverguardThreshold) {
      codeName = `${codeName}（${getEverguardSettingResult.data.getEverguardSetting.everguard_threshold}㎡ 以上）`;
    } else {
      codeName = `${codeName}（${getEverguardSettingResult.data.getEverguardSetting.everguard_threshold}㎡ 未満）`;
    }
  }

  let sortName;
  if (getPlaceSortNameResult.data) {
    sortName = getPlaceSortNameResult.data.getPlaceSort.name;
  } else {
    sortName = '指定なし';
  }

  return (
    <div className="PlaceCreateCodeAndSortPage-container">
      <Loading open={updatePlaceCodeSortConfigResult.loading} />
      <h1>補修コードと補修分類の組み合わせ編集</h1>
      <div className="PlaceCreateCodeAndSortPage-input_container">
        <label className="App-label">補修コード名</label>
        <div>
          <span>{codeName}</span>
        </div>
      </div>
      <div className="PlaceCreateCodeAndSortPage-input_container">
        <label className="App-label">補修種別名</label>
        <div>
          <span>{sortName}</span>
        </div>
      </div>
      <div className="PlaceCreateCodeAndSortPage-input_container">
        <label className="App-label">前置文字列</label>
        <div>
          <Input
            type="string"
            onChange={(event) => {
              const prefix = event.target.value;
              setPrefix(prefix);
            }}
            value={prefix}
          />
        </div>
      </div>
      <div className="PlaceCreateCodeAndSortPage-input_container">
        <label className="App-label">画層の色</label>
        <div className="PlaceCreateCodeAndSortPage-color_container">
          <div>
            <SketchPicker
              color={color}
              className="PlaceCreateCodeAndSortPage-color_picker"
              onChange={(color) => setColor(color.hex)}
            />
          </div>
        </div>
      </div>
      <div className="PlaceCreateCodeAndSortPage-input_container">
        <label className="App-label">線種</label>
        <div>
          <LineSelect
            value={lineType}
            onChange={(type) => {
              setLineType(type);
            }}
            containerClassName="PlaceCreateCodeAndSortPage-image_select"
            optionClassName="PlaceCreateCodeAndSortPage-image_option"
            />
        </div>
      </div>
      <div className="PlaceCreateCodeAndSortPage-input_container">
        <label className="App-label">テクスチャ</label>
        <div>
          <HatchSelect
            value={hatchType}
            onChange={(type) => {
              setHatchType(type);
            }}
            containerClassName="PlaceCreateCodeAndSortPage-image_select"
            optionClassName="PlaceCreateCodeAndSortPage-image_option"
            />
        </div>
      </div>
      <div className="PlaceCreateCodeAndSortPage-input_container">
        <Button onClick={() => {
          updatePlaceCodeSortConfig({
            variables: {
              input: {
                place_id: placeId,
                code_id: placeCodeId,
                sort_id: placeSortId,
                prefix: prefix,
                color: color,
                line_type: lineType,
                hatch_type: hatchType,
                disabled: false,
                place_code_everguard_id: getEverguardSettingResult.data?.getEverguardSetting?.id ?? undefined,
                gte_everguard_threshold: gteEverguardThreshold
              }
            }
          });

        }}>更新</Button>
      </div>
    </div>
  );
};
